@font-face {
  font-family: AppleSDGothicNeo;
  src: url(./assets/fonts/AppleSDGothicNeoT.ttf);
  font-weight: 100;
}
@font-face {
  font-family: AppleSDGothicNeo;
  src: url(./assets/fonts/AppleSDGothicNeoUL.ttf);
  font-weight: 200;
}
@font-face {
  font-family: AppleSDGothicNeo;
  src: url(./assets/fonts/AppleSDGothicNeoL.ttf);
  font-weight: 300;
}
@font-face {
  font-family: AppleSDGothicNeo;
  src: url(./assets/fonts/AppleSDGothicNeoR.ttf);
  font-weight: 400;
}
@font-face {
  font-family: AppleSDGothicNeo;
  src: url(./assets/fonts/AppleSDGothicNeoM.ttf);
  font-weight: 500;
}
@font-face {
  font-family: AppleSDGothicNeo;
  src: url(./assets/fonts/AppleSDGothicNeoSB.ttf);
  font-weight: 600;
}
@font-face {
  font-family: AppleSDGothicNeo;
  src: url(./assets/fonts/AppleSDGothicNeoB.ttf);
  font-weight: 700;
}
@font-face {
  font-family: AppleSDGothicNeo;
  src: url(./assets/fonts/AppleSDGothicNeoEB.ttf);
  font-weight: 800;
}
@font-face {
  font-family: AppleSDGothicNeo;
  src: url(./assets/fonts/AppleSDGothicNeoH.ttf);
  font-weight: 900;
}
